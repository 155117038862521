import {Inject, Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import * as i18nIsoCountries from 'i18n-iso-countries';
import {DecimalPipe} from '@angular/common';
import {HelperService} from './services/helper.service';
declare const require;
import * as cdigit from 'cdigit';
import * as cc from "currency-codes";
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class AppService {
  userGetByEmailQuery = gql`query UserGetByEmail($user: UserEmailInput, $options: SelectOptions) {
    userGetByEmail(user: $user, options: $options) {
      user {
        id
        email
        phone
      }
    }
  }`;

  companyGetByUserQuery = gql`query CompanyGetByUser($company: CompanyUsersInput, $options: SelectOptions) {
    companyGetByUser(company: $company, options: $options) {
      user {
        id
        companies {
          id
        }
      }
    }
  }`;

  companyGetByIdQuery = gql`query CompanyGetById($company: CompanyIdentInput, $options: SelectOptions) {
    companyGetById(company: $company, options: $options) {
      company {
        id
        taxId
        country
        name
        address
        isCustomer
        canBePrepaid
        isGovernmental
        status
        deleted
        localCurrency
      }
    }
  }`;

  getOperatorCountriesQuery = gql`query GetOperatorCountries($user: UserOperatorInput, $options: SelectOptions) {
    getOperatorCountries(user: $user, options: $options) {
      users {
        id
        firstName
        lastName
        address
        email
        phone
        role
        lastLogin
      }
      countries
    }
  }`;

  uploadDebCredQuery = gql`query UploadDebCredFile($request: FileRequestInput) {
    uploadDebCredFile(request: $request) {
      id
      bucket
      key
      uploadUrl
      version
    }
  }`;

  commitDebcredFileQuery = gql`query CommitDebcredFile(
    $debcredFileId: Int
    $country: String
    $round: RoundId
    $commitOptions: CommitOptions
  ) {
    commitDebcredFile(
    debcredFileId: $debcredFileId,
    country: $country,
    round: $round
    commitOptions: $commitOptions
    ) {
      processing {
        status
      }
    }
  }`;

  isDebCredFileCommitedQuery = gql`query IsDebCredFileCommited(
    $debcredFileId: Int
    $country: String
    $round: RoundId
  ) {
    isDebCredFileCommited(debcredFileId: $debcredFileId, country: $country, round: $round) {
      processing {
        status
        errors
        errorFile
        addedCount
        errorsCount
      }
    }
  }`;

  emailSendCongratsAfterCreationQuery = gql`query EmailSendCongratsAfterCreation(
    $user: UserIdentInput
    $company: CompanyIdentInput
    $invited: UserIdentInvitedInput
  ) {
    emailSendCongratsAfterCreation(user: $user, company: $company, invited: $invited) {
      emailSentNum
      sendErrors {
        to
        __error_message
        __msg_id
      }
    }
  }`;

  emailSendToManagerAfterCompanyRegistrationQuery = gql`query EmailSendToManagerAfterCompanyRegistration(
    $company: CompanyIdentInput
  ) {
    emailSendToManagerAfterCompanyRegistration(company: $company) {
      emailSentNum
      sendErrors {
        to
        __error_message
        __msg_id
      }
    }
  }`;

  emailSendToManagerAfterDocsUploadedQuery = gql`query EmailSendToManagerAfterDocsUploaded(
    $company: CompanyIdentInput
  ) {
    emailSendToManagerAfterDocsUploaded(company: $company) {
      emailSentNum
      sendErrors {
        to
        __error_message
        __msg_id
      }
    }
  }`;

  companyUploadDocumentQuery = gql`query CompanyUploadDocument($company: CompanyIdentInput, $documentName: String!, $documentFilename: String!) {
    companyUploadDocument(company: $company, documentName: $documentName, documentFilename: $documentFilename) {
      filename
      bucket
      key
      uploadUrl
    }
  }`;

  companyDocumentDeleteMutation = gql`mutation CompanyDocumentDelete($company: CompanyIdentInput, $documentFilename: String!) {
    companyDocumentDelete(company: $company, documentFilename: $documentFilename)
  }`;

  companyDocumentUploadCompletedMutation = gql`mutation CompanyDocumentUploadCompleted($company: CompanyIdentInput, $documentName: String, $documentFilename: String) {
    companyDocumentUploadCompleted(company: $company, documentName: $documentName, documentFilename: $documentFilename) {
      id
    }
  }`;

  listAllUsersQuery = gql`query ListAllUsers {
    listAllUsers {
      user{
        id
        firstName
        lastName
        address
        email
        phone
        role
        lastLogin
        __typename
      }
    }
  }`;

  addCountryOperatorMutation = gql`mutation AddCountryOperator($user: UserOperatorInput) {
    addCountryOperator(user: $user) {
      user {
        id
        firstName
        lastName
        address
        email
        phone
        role
        lastLogin
      }
    }
  }`;

  listCountryDocumentsQuery = gql`query ListCountryDocuments($company: CompanyIdentInput) {
    companyCountryDocuments(company: $company) {
      name
      filename
      order_number
      bucket
      key
      url
    }
  }`;

  listCompanyDocumentsQuery = gql`query ListCompanyDocuments($company: CompanyIdentInput) {
    companyListDocuments(company: $company) {
      name
      filename
      order_number
      bucket
      key
      url
    }
  }`;

  countriesGetAllQuery = gql`query CountriesGetAll {
    countriesGetAll {
      alpha3
      currency
      title
      mainMessage
      subMessage
    }
  }`;

  countriesGetActiveQuery = gql`query CountriesGetActive {
    countriesGetActive {
      alpha3
      currency
      title
      mainMessage
      subMessage
    }
  }`;

  connectExternalUserMutation = gql`mutation ConnectExternalUser($user: UserIdentInput) {
    connectExternalUser(user: $user) {
      user {
        id
      }
    }
  }`;

  systemAuthEnterMutation = gql`mutation SystemAuthEnter($user: UserIdentInput, $params: SystemEnterInput) {
    systemAuthEnter(user: $user, params: $params) {
      error
      errcode
      json
    }
  }`;

  auditLogGetFilteredQuery = gql`query AuditLogGetFiltered($filter: AuditLogFilterInput) {
    auditLogGetFiltered(filter: $filter) {
      log {
        timestamp
        json
      }
      numberLines
      mappedUsers {
        id
        firstName
        lastName
        email
        deleted
        lastLogin
      }
    }
  }`;

  getAuditLogsQuery = gql`query GetAuditLogs($filter: AuditLogFilterInput, $options: SelectOptions) {
    getAuditLogs(filter: $filter, options: $options) {
      count
      page
      pageSize
      logs {
        action
        actionData
        date
        user {
          firstName
          lastName
          email
        }
      }
    }
  }`;

  auditLogGetFileQuery = gql`query GetAuditLogFileSignedUrl(
    $version: String!
    $downloadType: DownloadType!
  ) {
    getAuditLogFileSignedUrl(
      version: $version
      downloadType: $downloadType
    )
  }`;

  downloadUnifiedUploadReportQuery = gql`query DownloadUnifiedUploadReport(
    $round: RoundId!
  ) {
    downloadUnifiedUploadReport(
      round: $round
    )
  }`;

  downloadUnifiedResultsReportQuery = gql`query DownloadUnifiedResultsReport(
    $round: RoundId!
  ) {
    downloadUnifiedResultsReport(
      round: $round
    )
  }`;

  downloadUnifiedFinalReportQuery = gql`query DownloadUnifiedFinalReport(
    $round: RoundId!
  ) {
    downloadUnifiedFinalReport(
      round: $round
    )
  }`;

  userIsRegisteredQuery = gql`query UserIsRegistered($user: UserEmailInput) {
    userIsRegistered(user: $user)
  }`;

  registrationProceedMutation = gql`mutation RegistrationProceed($user: UserAddInput, $company: CompanyInput) {
    registrationProceed(user: $user, company: $company) {
      error
      errcode
      json
    }
  }`;

  companyGetAdminsQuery = gql`query CompanyGetAdmins($company: CompanyIdentInput) {
    companyGetAdmins(company: $company) {
      users {
        id
        permissions {
          roles {
            id
            def
            title
          }
          perms {
            id
            def
            title
          }
        }
      }
    }
  }`;

  userGetStatusesQuery = gql`query userGetStatuses($company: CompanyIdentInput!, $round: RoundId!) {
    userGetStatuses(company: $company, round: $round) {
      companyStatus
      docsUploaded
      entries
      role
      roundStatus
      vendors
    }
  }`;

  getSingleReportSignedUrlQuery = gql`query GetSingleReportSignedUrl(
    $reportType: ReportType
    $round: RoundId
    $company: CompanyIdentInput
  ) {
    getSingleReportSignedUrl(
      reportType: $reportType
      round: $round
      company: $company
    ) {
      progress
      link
    }
  }`;

  preliminarySingleReportQuery = gql`query PreliminarySingleReport($round: RoundId!, $company: CompanyIdentInput!) {
    preliminarySingleReport(round: $round, company: $company)
  }`;

  finalSingleReportQuery = gql`query FinalSingleReport($round: RoundId!, $company: CompanyIdentInput!) {
    finalSingleReport(round: $round, company: $company)
  }`;

  errorReportQuery = gql`query ErrorReport($round: RoundId!, $fileId: String) {
    errorReport(
      round: $round
      fileId: $fileId
    )
  }`;

  notifyingSingleReportQuery = gql`query NotifyingSingleReport($round: RoundId!, $company: CompanyIdentInput!) {
    notifyingSingleReport(round: $round, company: $company)
  }`;

  constructor(private apollo: Apollo,
              private decimalPipe: DecimalPipe,
              private helperService: HelperService,
              @Inject('moment') private moment) {}

  getSingleReportSignedUrl(reportType, roundId, companyId) {
    return this.apollo.query({
      fetchPolicy: 'network-only',
      query: this.getSingleReportSignedUrlQuery,
      variables: {
        reportType,
        company: {
          id: companyId
        },
        round: {
          id: roundId
        },
      }
    });
  }

  preliminarySingleReport(roundId, companyId) {
    return this.apollo.query({
      fetchPolicy: 'network-only',
      query: this.preliminarySingleReportQuery,
      variables: {
        company: {
          id: companyId
        },
        round: {
          id: roundId
        },
      }
    });
  }

  finalSingleReport(roundId, companyId) {
    return this.apollo.query({
      fetchPolicy: 'network-only',
      query: this.finalSingleReportQuery,
      variables: {
        company: {
          id: companyId
        },
        round: {
          id: roundId
        },
      }
    });
  }

  errorReport(roundId, fileId): Observable<any> {
    return this.apollo.query({
      fetchPolicy: 'network-only',
      query: this.errorReportQuery,
      variables: {
        round: {
          id: roundId
        },
        fileId
      }
    }).pipe(
      map((data: any) => {
        return data.data.errorReport;
      })
    );
  }

  notifyingSingleReport(roundId, companyId) {
    return this.apollo.query({
      fetchPolicy: 'network-only',
      query: this.notifyingSingleReportQuery,
      variables: {
        company: {
          id: companyId
        },
        round: {
          id: roundId
        },
      }
    });
  }

  userGetByEmail(email) {
    return this.apollo.query({
      query: this.userGetByEmailQuery,
      fetchPolicy: 'network-only',
      variables: {
        user: {
          email
        }
      }
    });
  }

  companyGetByUser(id) {
    return this.apollo.query({
      query: this.companyGetByUserQuery,
      fetchPolicy: 'network-only',
      variables: {
        company: {
          users: [{
            id
          }]
        }
      }
    });
  }

  companyGetById(id) {
    return this.apollo.query({
      fetchPolicy: 'network-only',
      query: this.companyGetByIdQuery,
      variables: {
        company: {
          id
        }
      }
    });
  }

  getOperatorCountries(userId) {
    return this.apollo.query({
      query: this.getOperatorCountriesQuery,
      fetchPolicy: 'network-only',
      variables: {
        user: {
          user: userId,
        }
      }
    });
  }

  uploadDebCred(country, companyId, roundId, extension, type) {
    return this.apollo.query({
      query: this.uploadDebCredQuery,
      fetchPolicy: 'network-only',
      variables: {
        request: {
          country,
          companyId,
          roundId,
          extension,
          type,
        }
      }
    });
  }

  commitDebcredFile(version, country, roundId, commitOptions) {
    console.log(roundId);
    return this.apollo.query({
      query: this.commitDebcredFileQuery,
      fetchPolicy: 'network-only',
      variables: {
        debcredFileId: version,
        country,
        round: {
          id: roundId
        },
        commitOptions
      }
    });
  }

  isDebCredFileCommited(version, country, roundId) {
    return this.apollo.query({
      query: this.isDebCredFileCommitedQuery,
      fetchPolicy: 'network-only',
      variables: {
        debcredFileId: version,
        country,
        round: {
          id: roundId
        }
      }
    });
  }

  emailSendCongratsAfterCreation(userId, companyId, invited?) {
    console.log(invited);

    return this.apollo.query({
      query: this.emailSendCongratsAfterCreationQuery,
      fetchPolicy: 'network-only',
      variables: {
        user: {
          id: userId,
        },
        company: {
          id: companyId,
        },
        invited: {
          id: invited,
        },
      }
    });
  }

  listAllUsers() {
    return this.apollo.query({
      fetchPolicy: 'network-only',
      query: this.listAllUsersQuery,
    });
  }

  addCountryOperator(user, country) {
    console.log(user, country);

    return this.apollo.mutate({
      mutation: this.addCountryOperatorMutation,
      variables: {
        user: {
          user,
          country
        },
      }
    });
  }

  companyUploadDocument(companyId, documentName, documentFilename) {
    return this.apollo.query({
      query: this.companyUploadDocumentQuery,
      fetchPolicy: 'network-only',
      variables: {
        company: {
          id: companyId
        },
        documentName,
        documentFilename
      }
    });
  }

  companyDocumentDelete(companyId, documentFilename) {
    return this.apollo.mutate({
      mutation: this.companyDocumentDeleteMutation,
      variables: {
        company: {
          id: companyId
        },
        documentFilename
      }
    });
  }

  companyDocumentUploadCompleted(companyId, documentName, documentFilename) {
    return this.apollo.mutate({
      mutation: this.companyDocumentUploadCompletedMutation,
      variables: {
        company: {
          id: companyId
        },
        documentName,
        documentFilename
      },
    });
  }

  registrationProceed(user) {
    delete user.role;

    return this.apollo.use('unauth').mutate({
      mutation: this.registrationProceedMutation,
      variables: {
        user
      }
    });
  }

  listCountryDocuments(country) {
    return this.apollo.query({
      query: this.listCountryDocumentsQuery,
      fetchPolicy: 'network-only',
      variables: {
        company: {
          country
        }
      }
    });
  }

  listCompanyDocuments(company) {
    return this.apollo.query({
      query: this.listCompanyDocumentsQuery,
      fetchPolicy: 'network-only',
      variables: {
        company: {
          id: company
        }
      }
    });
  }

  parseAmount(value): number {
    if (String(value).substring(String(value).length - 3, String(value).length).indexOf(',') === 0) {
      value = String(value).replace('- ', '-').replace(' ', '');

      value = String(value).substr(0, String(value).length - 3).replace(/\./g, '') + '.' + String(value).substr(String(value).length - 2);
    } else {
      value = String(value).replace(/,/g, '').replace(' ', '');

      // console.log(value);
    }

    // console.log(Math.round(parseFloat(value) * 100) / 100);

    // console.log(value);
    // if (String(value).substring(String(value).length - 2, String(value).length).indexOf(',')) {
    //   String(value).replace()
    // }
    // console.log(String(value).substring(0, String(value).length - 2));
    // console.log(parseFloat(value));
    return Math.round(parseFloat(value) * 100) / 100;
  }

  countriesGetAll() {
    return this.apollo.watchQuery({
      query: this.countriesGetAllQuery
    });
  }

  countriesGetActive() {
    return this.apollo.use('unauth').query({
      query: this.countriesGetActiveQuery
    });
  }

  userIsRegistered(email) {
    return this.apollo.use('unauth').query({
      query: this.userIsRegisteredQuery,
      fetchPolicy: 'network-only',
      variables: {
        user: {
          email
        }
      }
    });
  }

  getCountries() {
    const fakeCountries = [{
      content: 'Limeland',
      name: 'Limeland',
      en: 'Limeland',
      mk: 'Limeland',
      hr: 'Limeland',
      sr: 'Limeland',
      cn: 'Limeland',
      el: 'Limeland',
      code: 'XXL',
      alpha2: 'XXL',
    }, {
      content: 'Indigoland',
      name: 'Indigoland',
      en: 'Indigoland',
      mk: 'Indigoland',
      hr: 'Indigoland',
      sr: 'Indigoland',
      cn: 'Indigoland',
      el: 'Indigoland',
      code: 'XXI',
      alpha2: 'XXI',
    }, {
      content: 'Greyland',
      name: 'Greyland',
      en: 'Greyland',
      mk: 'Greyland',
      hr: 'Greyland',
      sr: 'Greyland',
      cn: 'Greyland',
      el: 'Greyland',
      code: 'XXG',
      alpha2: 'XXG',
    }, {
      content: 'Brownland',
      name: 'Brownland',
      en: 'Brownland',
      mk: 'Brownland',
      hr: 'Brownland',
      sr: 'Brownland',
      cn: 'Brownland',
      el: 'Brownland',
      code: 'XXB',
      alpha2: 'XXB',
    }, {
      content: 'Maroonland',
      name: 'Maroonland',
      en: 'Maroonland',
      mk: 'Maroonland',
      hr: 'Maroonland',
      sr: 'Maroonland',
      cn: 'Maroonland',
      el: 'Maroonland',
      code: 'XXM',
      alpha2: 'XXM',
    }, {
      content: 'Silverland',
      name: 'Silverland',
      en: 'Silverland',
      mk: 'Silverland',
      hr: 'Silverland',
      sr: 'Silverland',
      cn: 'Silverland',
      el: 'Silverland',
      code: 'XXS',
      alpha2: 'XXS',
    }, {
      content: 'Yellowland',
      name: 'Yellowland',
      en: 'Yellowland',
      mk: 'Yellowland',
      hr: 'Yellowland',
      sr: 'Yellowland',
      cn: 'Yellowland',
      el: 'Yellowland',
      code: 'XXY',
      alpha2: 'XXY',
    }, {
      content: 'Zompland',
      name: 'Zompland',
      en: 'Zompland',
      mk: 'Zompland',
      hr: 'Zompland',
      sr: 'Zompland',
      cn: 'Zompland',
      el: 'Zompland',
      code: 'XXZ',
      alpha2: 'XXZ',
    }, {
      content: 'Fallowland',
      name: 'Fallowland',
      en: 'Fallowland',
      mk: 'Fallowland',
      hr: 'Fallowland',
      sr: 'Fallowland',
      cn: 'Fallowland',
      el: 'Fallowland',
      code: 'XXF',
      alpha2: 'XXF',
    }, {
      content: 'Etonland',
      name: 'Etonland',
      en: 'Etonland',
      mk: 'Etonland',
      hr: 'Etonland',
      sr: 'Etonland',
      cn: 'Etonland',
      el: 'Etonland',
      code: 'XXE',
      alpha2: 'XXE',
    }, {
      content: 'Dimeland',
      name: 'Dimeland',
      en: 'Dimeland',
      mk: 'Dimeland',
      hr: 'Dimeland',
      sr: 'Dimeland',
      cn: 'Dimeland',
      el: 'Dimeland',
      code: 'XXD',
      alpha2: 'XXD',
    }];

    i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
    i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/mk.json'));
    i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/hr.json'));
    i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/sr.json'));
    i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/el.json'));

    let realCountries = Object.keys(i18nIsoCountries.getNames('en')).map(code => {
      return {
        content: this.customCountryName(code),
        name: this.customCountryName(code),
        code: i18nIsoCountries.alpha2ToAlpha3(code),
        alpha2: i18nIsoCountries.toAlpha2(code),
        en: i18nIsoCountries.getNames('en')[code],
        mk: i18nIsoCountries.getNames('mk')[code],
        hr: i18nIsoCountries.getNames('hr')[code],
        sr: i18nIsoCountries.getNames('sr')[code],
        cn: i18nIsoCountries.getNames('hr')[code],
        el: i18nIsoCountries.getNames('el')[code],
      };
    });

    // if (!environment.production) {
    //   realCountries.push(...fakeCountries);
    // }

    realCountries = realCountries.filter(item => item['code'] !== 'XKX');

    realCountries.push(...fakeCountries);

    realCountries.sort((a, b) => {
      return a.content.localeCompare(b.content);
    });

    return realCountries;
  }

  customCountryName(code) {
    let name;

    if (code === 'MK') {
      name = 'North Macedonia';
    } else if (code === 'PS') {
      name = 'Palestinian territory';
    } else {
      name = i18nIsoCountries.getNames('en')[code];
    }
    return name;
  }

  sendEmailToManager(companyId) {
    return this.apollo.query({
      fetchPolicy: 'network-only',
      query: this.emailSendToManagerAfterCompanyRegistrationQuery,
      variables: {
        company: {
          id: companyId,
        },
      }
    });
  }

  sendEmailToManagerAboutDocs(companyId) {
    return this.apollo.query({
      fetchPolicy: 'network-only',
      query: this.emailSendToManagerAfterDocsUploadedQuery,
      variables: {
        company: {
          id: companyId,
        },
      }
    });
  }

  userGetStatuses(companyId, roundId) {
    return this.apollo.query({
      fetchPolicy: 'network-only',
      query: this.userGetStatusesQuery,
      variables: {
        company: {
          id: companyId,
        },
        round: {
          id: roundId,
        },
      }
    });
  }

  connectExternalUser(userId) {
    return this.apollo.mutate({
      mutation: this.connectExternalUserMutation,
      variables: {
        user: {
          id: userId
        },
      }
    });
  }

  systemAuthEnter(userId) {
    return this.apollo.mutate({
      mutation: this.systemAuthEnterMutation,
      variables: {
        user: {
          id: userId
        },
      }
    });
  }

  formatAmount(value) {
    // console.log(this.decimalPipe.transform(value, '1.2-2'));

    return this.decimalPipe.transform(value, '1.2-2');
  }

  auditLogGetFiltered(companyId, date) {
    return this.apollo.query({
      query: this.auditLogGetFilteredQuery,
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          companies: [companyId],
          date: date
        }
      }
    });
  }

  getAuditLogs(companyId, date, options?) {
    return this.apollo.query({
      query: this.getAuditLogsQuery,
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          companies: [companyId],
          date: date
        },
        options,
      }
    });
  }

  auditLogGetFile(version, downloadType) {
    return this.apollo.query({
      query: this.auditLogGetFileQuery,
      fetchPolicy: 'network-only',
      variables: {
        version,
        downloadType
      }
    });
  }

  downloadUnifiedUploadReport(roundId) {
    return this.apollo.query({
      query: this.downloadUnifiedUploadReportQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        }
      }
    });
  }

  downloadUnifiedResultsReport(roundId) {
    return this.apollo.query({
      query: this.downloadUnifiedResultsReportQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        }
      }
    });
  }

  downloadUnifiedFinalReport(roundId) {
    return this.apollo.query({
      query: this.downloadUnifiedFinalReportQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        }
      }
    });
  }

  companyGetAdmins(id) {
    return this.apollo.query({
      query: this.companyGetAdminsQuery,
      fetchPolicy: 'network-only',
      variables: {
        company: {
          id
        }
      }
    });
  }

  validateTaxControl(tax, country?) {
    const taxArray = [...`${tax}`];
    const reduceArray = [...`${tax}`];

    if (this.helperService.oneOf(country, ['BIH', 'MKD'])) {
      if (taxArray.length !== 13) {
        return null;
      } else {
        reduceArray.pop();

        let iterator = 8;

        let control = reduceArray.reduce((acc: any, item: any): any => {
          if (iterator <= 8 && iterator > 2) {
            iterator--;
          } else {
            iterator = 7;
          }

          // console.log(iterator);

          return acc + (iterator * item);
        }, 0);

        // console.log(control);

        // control = (11 - control) % 11;

        const mod = (a, b) => ((a % b) + b) % b;

        control = mod(11 - control, 11);

        // console.log(control);

        if (control >= 10) {
          control = 0;
        }

        // console.log(taxArray);

        if (taxArray[taxArray.length - 1] == control) {
          return tax;
        }

        return null;
      }
    }

    if (country === 'SRB') {
      if (taxArray.length !== 9) {
        return null;
      } else {
        if (cdigit.mod11_10.validate(tax)) {
          return tax;
        }
        return null;
      }
    }

    if (country === 'MNE') {
      if (taxArray.length !== 8) {
        return null;
      }
    }

    return tax;
  }

  validateAndParseDate(rawDate) {
    let value;

    switch (true) {
      case this.moment(rawDate, 'DD/MM', true).isValid():
        value = this.moment(rawDate, 'DD/MM', true).utc().local().format();
        break;

      case this.moment(rawDate, 'DD.MM', true).isValid():
        value =  this.moment(rawDate, 'DD.MM', true).utc().local().format();
        break;

      case this.moment(rawDate, 'DD.M', true).isValid():
        value =  this.moment(rawDate, 'DD.M', true).utc().local().format();
        break;

      case this.moment(rawDate, 'DD.M.YYYY', true).isValid():
        value =  this.moment(rawDate, 'DD.M.YYYY', true).utc().local().format();
        break;

      case this.moment(rawDate, 'DD MM', true).isValid():
        value =  this.moment(rawDate, 'DD MM', true).utc().local().format();
        break;

      case this.moment(rawDate, 'DD/MM/YYYY', true).isValid():
        value =  this.moment(rawDate, 'DD/MM/YYYY', true).utc().local().format();
        break;

      case this.moment(rawDate, 'DD.MM.YYYY', true).isValid():
        value =  this.moment(rawDate, 'DD.MM.YYYY', true).utc().local().format();
        break;

      case this.moment(rawDate, 'DD MM YYYY', true).isValid():
        value =  this.moment(rawDate, 'DD MM YYYY', true).utc().local().format();
        break;

      case this.moment(rawDate, 'DD-MMM', true).isValid():
        value =  this.moment(rawDate, 'DD-MMM', true).utc().local().format();
        break;

      case this.moment(rawDate, 'DD.MMM', true).isValid():
        value =  this.moment(rawDate, 'DD.MMM', true).utc().local().format();
        break;

      case this.moment(rawDate, 'DD MMM', true).isValid():
        value =  this.moment(rawDate, 'DD MMM', true).utc().local().format();
        break;

      default:
        value = null;
    }

    return value;
  }

  getCurrencies() {
    let currencies = cc.codes().filter(item => item !== 'XXX');

    currencies = [...currencies, 'XXL', 'XXI', 'XXG', 'XXB', 'XXM', 'XXS', 'XXY', 'XXZ', 'XXF', 'XXE', 'XXD'];

    return currencies;
  }

  hasState(country) {
    const states = ['USA', 'CAN', 'ESP', 'AUS', 'CHN', 'MEX', 'MYS'];

    return states.includes(country);
  }
}
