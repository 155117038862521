import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
declare var Cookiebot: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  lang = 'en';

  constructor(public translate: TranslateService) {}

  ngOnInit() {
    setTimeout(() => {
      this.lang = this.translate.currentLang;

    //   const script = document.getElementById('Cookiebot');
    //
    //   script.setAttribute('data-culture', this.lang);
    //
    //   window.addEventListener('CookiebotOnAccept', (e) => {
    //     const body = document.body;
    //
    //     if (Cookiebot.consented) {
    //       body.setAttribute('data-consented', 'true');
    //     } else {
    //       body.removeAttribute('data-consented');
    //     }
    //   }, true);
    }, 0);

    this.subscription.add(this.translate.onLangChange
      .subscribe(data => {
        this.lang = data['lang'];

        // const script = document.getElementById('Cookiebot');

        // script.setAttribute('data-culture', this.lang);
      }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // buildScriptTag() {
  //   const script = document.createElement('script');
  //   script['type'] = 'text/javascript';
  //   script['id'] = 'Cookiebot';
  //   script['src'] = 'https://consent.cookiebot.com/uc.js';
  //   script.setAttribute('data-blockingmode', 'auto');
  //   script.setAttribute('data-cbid', 'c50b30ce-c85f-4493-b94c-6d71bb6ee465');
  //   script.setAttribute('data-culture', this.lang);
  //
  //   return script;
  // }
}
