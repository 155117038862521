import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../../app.service';
import {HelperService} from '../../services/helper.service';
import {ReferralsService} from '../../services/referrals.service';
import {Subscription} from 'rxjs';
import {NotificationService} from 'carbon-components-angular';
import {AuthService} from '../../shared/auth.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-company-vendors-modal',
  templateUrl: './company-vendors-modal.component.html',
  styleUrls: ['./company-vendors-modal.component.scss']
})
export class CompanyVendorsModalComponent implements OnInit {
  @Output() close = new EventEmitter();
  formGroup: FormGroup;
  isLoading = false;
  title = 'Add Company';
  countries = [];
  private subscription: Subscription = new Subscription();
  userId;
  companyId;
  company;
  authInfo;

  constructor(@Inject('id') public id,
              @Inject('other') public other,
              protected formBuilder: FormBuilder,
              private appService: AppService,
              private helperService: HelperService,
              private referralsService: ReferralsService,
              private notificationService: NotificationService,
              private authService: AuthService,
              public translate: TranslateService) { }

  ngOnInit() {
    console.log('Company ID', this.id);

    this.countries = this.appService.getCountries();

    this.subscription.add(this.authService.authInfo$.subscribe(authInfo => {
      this.authInfo = authInfo;

      if (authInfo.email) {
        this.appService.userGetByEmail(authInfo.email)
          .subscribe(data => {
            // console.log(data);

            this.userId = data['data']['userGetByEmail']['user']['id'];

            this.appService.companyGetByUser(this.userId)
              .subscribe(data => {
                // console.log(data);

                if (authInfo.currentCompany) {
                  this.companyId = authInfo.currentCompany;
                } else {
                  this.companyId = data['data']['companyGetByUser'] && data['data']['companyGetByUser']['user']['companies'][0]['id'];
                }

                this.appService.companyGetById(this.companyId)
                  .subscribe(data => {
                    // console.log(data);

                    this.isLoading = false;

                    this.company = data['data']['companyGetById'] && data['data']['companyGetById']['company'];
                  });
              });
          });
      }
    }));

    this.formGroup = this.formBuilder.group({
      taxId: ['', [
        Validators.required,
        Validators.pattern(/.*\S+.*/),
        Validators.pattern(/^[\w.-/$&+,:;=?@#|'<>.^*()%!-–]+$/)
      ]
      ],
      name: ['', [
        Validators.required,
        Validators.pattern(/.*\S+.*/)
      ]],
      country: ['', Validators.required],
      email: ['', [
        Validators.pattern(/.*\S+.*/),
        Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')
      ]],
      firstName: ['', [
        Validators.pattern(/.*\S+.*/),
      ]],
      lastName: ['', [
        Validators.pattern(/.*\S+.*/),
      ]],
      phone: [''],
    });

    if (this.id) {
      this.title = 'Edit Company';
    }
  }

  isErrorVisible(field, error, select?) {
    if (select) {
      return this.formGroup.controls[field].touched
        && this.formGroup.controls[field].errors
        && this.formGroup.controls[field].errors[error];
    } else if (field == 'taxId'
      && this.formGroup.get('country').value && this.helperService.oneOf(this.formGroup.get('country').value, ['BIH', 'MKD', 'SRB', 'MNE'])
      && this.formGroup.controls[field].value
    ) {
      const illegal = !this.appService.validateTaxControl(
        this.formGroup.controls[field].value,
        this.formGroup.get('country').value
      );

      this.formGroup.controls[field].setErrors(illegal ? { illegal } : null);

      return this.formGroup.controls[field].dirty
        && this.formGroup.controls[field].errors
        && this.formGroup.controls[field].errors[error];
    } else {
      return this.formGroup.controls[field].dirty
        && this.formGroup.controls[field].errors
        && this.formGroup.controls[field].errors[error];
    }
  }

  closeModal(): void {
    this.close.emit();
  }

  showNotification(type, message) {
    this.notificationService.showNotification({
      type,
      title: this.translate.instant('upload.file.file'),
      message,
      target: '.notification-container',
      showClose: true,
      duration: 30000
    });
  }

  addCompany() {
    this.isLoading = true;

    this.referralsService.referralAdd({
      referringCompanyId: this.companyId,
      referringUserId: this.userId,
      taxId: this.formGroup.get('taxId').value,
      companyName: this.formGroup.get('name').value,
      country: this.formGroup.get('country').value,
      contactEmail: this.formGroup.get('email').value,
      contactFirstName: this.formGroup.get('firstName').value,
      contactLastName: this.formGroup.get('lastName').value,
      contactPhone: this.formGroup.get('phone').value,
    }, this.companyId)
      .subscribe(data => {
        const {id: referralId} = data['data']['referralAdd']['referral'];

        if (this.formGroup.get('email').value || this.formGroup.get('phone').value) {
          this.referralsService.sendEmailToManager([{id: referralId}], this.companyId)
            .subscribe(data => {
              this.isLoading = false;
            }, (error) => {
              this.showNotification('error', error['graphQLErrors'][0]['message']);

              console.log('there was an error sending the query', error);
            });
        }
        this.isLoading = false;

        this.close.emit();

        this.other.next(true);

        this.showNotification('success', this.translate.instant('company.vendors.added'));
      }, (error) => {
        this.isLoading = false;

        console.log('there was an error sending the query', error);
      });
  }
}
