import {Component, Inject, Input, OnDestroy, OnInit, Renderer} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss']
})
export class AuthHeaderComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  lang = 'en';
  langs = {
    en: 'English',
    mk: 'Македонски',
    hr: 'Hrvatski',
    sr: 'Srpski',
    cn: 'Crnogorski',
    el: 'Ελληνικα'
  };
  @Input() link = true;
  langsCodes = Object.keys(this.langs);
  queryLang;
  isOpen = false;

  constructor(public translate: TranslateService,
              private renderer: Renderer,
              @Inject(DOCUMENT) private document,
              private route: ActivatedRoute) {
    this.translate.addLangs(this.langsCodes);

    this.translate.setDefaultLang(this.lang);

    if (JSON.parse(localStorage.getItem('lang'))) {
      this.lang = JSON.parse(localStorage.getItem('lang'));

      this.translate.use(this.lang);
    } else {
      if (this.langsCodes.includes(this.translate.getBrowserLang())) {
        this.lang = this.translate.getBrowserLang();

        this.translate.use(this.lang);
      } else {
        this.translate.use(this.lang);
      }
    }
  }

  ngOnInit() {
    this.queryLang = this.route.snapshot.queryParams['lang'];

    if (this.queryLang) {
      localStorage.setItem('lang', JSON.stringify(this.queryLang));

      this.translate.setDefaultLang(this.lang);

      this.translate.use(this.queryLang);
    }

    this.document.documentElement.lang = this.translate.currentLang;

    this.subscription.add(this.translate.onLangChange
      .subscribe(data => {
        this.lang = data['lang'];

        this.document.documentElement.lang = this.lang;

        this.translate.setDefaultLang(this.lang);

        this.translate.use(this.lang);
      }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setCurrentLanguage(lang) {
    localStorage.setItem('lang', JSON.stringify(lang));

    this.translate.use(lang);

    this.toogle();
  }

  toogle() {
    setTimeout(data => {
      this.isOpen = !this.isOpen;
    }, 0);
  }
}
