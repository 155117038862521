import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from 'carbon-components-angular';
import {ActivatedRoute, Router} from '@angular/router';
import {CompaniesService} from '../../services/companies.service';
import {PermissionsService} from '../../services/permissions.service';
import {AppService} from '../../app.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../shared/auth.service';

@Component({
  selector: 'app-company-users-modal',
  templateUrl: './company-users-modal.component.html',
  styleUrls: ['./company-users-modal.component.scss']
})
export class CompanyUsersModalComponent implements OnInit {
  @Output() close = new EventEmitter();
  formGroup: FormGroup;
  isLoading = false;
  companyId;
  userId;
  title = this.translate.instant('company.users.button.add');
  userRole;
  currentUserRole;
  authInfo;
  roles = [];

  constructor(@Inject('id') public id,
              @Inject('currentUser') public currentUser,
              protected formBuilder: FormBuilder,
              private companiesService: CompaniesService,
              private notificationService: NotificationService,
              private route: ActivatedRoute,
              private router: Router,
              private permissionsService: PermissionsService,
              private appService: AppService,
              public translate: TranslateService,
              private authService: AuthService) { }

  ngOnInit() {
    console.log(this.id);

    this.authService.authInfo$.subscribe(authInfo => {

      this.userRole = authInfo.userRole;

      this.authInfo = authInfo;

      if (authInfo.email) {
        this.appService.userGetByEmail(authInfo.email)
          .subscribe(data => {
            // console.log(data);

            this.userId = data['data']['userGetByEmail']['user']['id'];

            this.appService.companyGetByUser(this.userId)
              .subscribe(data => {
                console.log(data);

                if (authInfo.currentCompany) {
                  this.companyId = authInfo.currentCompany;
                } else {
                  this.companyId = data['data']['companyGetByUser'] && data['data']['companyGetByUser']['user']['companies'][0]['id'];
                }
              });
          });
      }
    }, (error) => {
      this.showNotification('error', error['graphQLErrors'][0]['message']);

      console.log('there was an error sending the query', error);
    });

    this.formGroup = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.pattern(/.*\S+.*/),
        Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')
      ]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      title: [''],
      phone: [''],
      role: [{
        id: '3',
        content: 'View',
        title: 'View',
      }],
    });

    this.formGroup.get('role').patchValue({
      id: '3',
      content: 'View',
      title: 'View',
    });

    this.route.queryParams.subscribe(params => {
      this.companyId = params['company_id'];
    });

    if (this.id) {
      this.title = this.translate.instant('company.users.modal.title.edit');

      this.getUser(this.id);
    }

    this.permissionsService.permissionsRoleList()
      .subscribe(roles => {
        this.roles = roles['data']['permissionsRoleList']['permissions']['roles'].map(item => {
          return {
            id: item.id,
            // content: item.title,
            title: item.title,
            selected: item.id === '3',
            content: this.getI18NRoles(item.title)
          };
        }).filter(item => item.id === '1' || item.id === '2' || item.id === '3')
          .filter(item => item.id !== '6' && item.id !== '7');

        console.log(this.roles);

        this.id && this.companiesService.permissionsGetUserRoles(this.id, this.companyId).valueChanges
          .subscribe(data => {
            this.roles = roles['data']['permissionsRoleList']['permissions']['roles'].map(item => {
              if (data['data']['permissionsGetUserRoles'] && (item['id'] == data['data']['permissionsGetUserRoles']['user']['permissions']['roles'][0]['id'])) {
                item['selected'] = true;

                this.formGroup.get('role').patchValue(item);

                console.log(this.formGroup.get('role').value);
              } else {
                item['selected'] = false;
              }

              return {
                id: item.id,
                // content: item.title,
                title: item.title,
                selected: item.selected,
                content: this.getI18NRoles(item.title)
              };
            }).filter(item => item.id === '1' || item.id === '2' || item.id === '3')
              .filter(item => item.id !== '6' && item.id !== '7');

            this.currentUserRole = data['data']['permissionsGetUserRoles']['user']['permissions']['roles'][0]['title'];

            if (this.userRole === 'Operator' && this.currentUserRole === 'Super Admin') {
              this.formGroup.get('role').disable();
            }

            if (this.userRole === 'Admin' && this.currentUserRole === 'Super Admin') {
              this.formGroup.get('role').disable();
            }

            if (this.userRole === 'Admin' && this.currentUserRole === 'Operator') {
              this.formGroup.get('role').disable();
            }
          }, (error) => {
            this.showNotification('error', error['graphQLErrors'][0]['message']);

            console.log('there was an error sending the query', error);
          });
      });
  }

  getUser(id) {
    this.companiesService.userGetById(this.id).valueChanges
      .subscribe(data => {
        this.formGroup.patchValue(data['data']['userGetById']['user']);
      }, (error) => {
        this.showNotification('error', error['graphQLErrors'][0]['message']);

        console.log('there was an error sending the query', error);
      });
  }

  isErrorVisible(field, error, select?) {
    if (select) {
      return this.formGroup.controls[field].touched
        && this.formGroup.controls[field].errors
        && this.formGroup.controls[field].errors[error];
    } else {
      return this.formGroup.controls[field].dirty
        && this.formGroup.controls[field].errors
        && this.formGroup.controls[field].errors[error];
    }
  }

  closeModal(): void {
    this.close.emit();
  }

  showNotification(type, message) {
    this.notificationService.showNotification({
      type,
      title: this.translate.instant('company.details.tab.users'),
      message,
      target: '.notification-container',
      duration: 30000
    });
  }

  checkUser() {
    this.isLoading = true;

    this.appService.userGetByEmail(String(this.formGroup.get('email').value).toLowerCase())
      .subscribe(data => {
        this.isLoading = false;

        this.id = data['data']['userGetByEmail'] && data['data']['userGetByEmail']['user']['id'];

        if (this.id) {
          this.getUser(this.id);
        }

        this.addUser();
      }, (error) => {
        this.addUser();
      });
  }

  addUser() {
    // console.log(this.userRole);
    //
    // console.log(this.currentUserRole);

    if (this.userRole === 'Operator' && this.currentUserRole === 'Super Admin') {
      this.showNotification('warning', 'You have no permissions to edit this user');

      return false;
    }

    if (this.userRole === 'Admin' && this.currentUserRole === 'Super Admin') {
      this.showNotification('warning', 'You have no permissions to edit this user');

      return false;
    }

    if (this.userRole === 'Admin' && this.currentUserRole === 'Operator') {
      this.showNotification('warning', 'You have no permissions to edit this user');

      return false;
    }

    if (this.id) {
      console.log(this.id);

      this.companiesService.modifyUser(this.id, this.formGroup.value, this.companyId)
        .subscribe(data => {

          this.appService.emailSendCongratsAfterCreation(this.id, this.companyId, this.currentUser)
            .subscribe(data => {
              console.log(data);
            });

          // this.assignUserRole(this.id);

          this.companiesService.assignCompanyUser(this.companyId, this.id, this.formGroup.get('role').untouched ? 3 : this.formGroup.get('role').value.id)
            .subscribe(data => {
              this.isLoading = false;

              this.close.emit();

              this.showNotification('success', this.translate.instant('company.users.edited'));

            }, (error) => {
              this.isLoading = false;

              this.close.emit();

              this.showNotification('success', this.translate.instant('company.users.assigned'));

              console.log('there was an error sending the query', error);
            });
        }, (error) => {
          this.isLoading = false;

          this.close.emit();

          error.graphQLErrors.map((e) => {
            if (e.errorInfo && e.errorInfo.statusCode === 1121) {
              this.showNotification('error', `${this.translate.instant('errors.used.modify')}`);
            } else {
              this.showNotification('error', error['graphQLErrors'][0]['message']);
            }
          });

          console.log('there was an error sending the query', error);
        });
    } else {
      this.companiesService.addUser(this.companyId, this.formGroup.value)
        .subscribe(data => {
          console.log(data);

          this.userId = data['data']['addUser']['user']['id'];

          this.appService.emailSendCongratsAfterCreation(this.userId, this.companyId, this.currentUser)
            .subscribe(data => {
              console.log(data);
            });

          // this.assignUserRole(this.userId);

          this.companiesService.assignCompanyUser(this.companyId, this.userId, this.formGroup.get('role').untouched ? 3 : this.formGroup.get('role').value.id)
            .subscribe(data => {
              this.isLoading = false;

              this.close.emit();

              this.showNotification('success', this.translate.instant('company.users.added'));

            }, (error) => {
              this.isLoading = false;

              this.showNotification('error', error['graphQLErrors'][0]['message']);

              console.log('there was an error sending the query', error);
            });
        }, (error) => {
          this.isLoading = false;

          this.close.emit();

          error.graphQLErrors.map((e) => {
            if (e.errorInfo && e.errorInfo.statusCode === 1122) {
              this.showNotification('error', `${this.translate.instant('errors.used.add')}`);
            } else if (e.errorInfo && e.errorInfo.statusCode === 1101) {
              this.showNotification('error', `${this.translate.instant('errors.user.exist')}`);
            } else {
              this.showNotification('error', this.translate.instant('company.users.dup'));
            }
          });

          console.log('there was an error sending the query', error);
        });
    }
  }

  assignUserRole(userId) {
    this.isLoading = true;

    this.permissionsService.permissionsAssignUserRole(userId, [this.formGroup.get('role').untouched ? {
      id: '3',
      content: 'View',
      title: 'View',
    } : this.formGroup.get('role').value], this.companyId)
      .subscribe(data => {
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;

        this.showNotification('error', error['graphQLErrors'][0]['message']);

        console.log('there was an error sending the query', error);
      });
  }

  getI18NRoles(role) {
    switch (role) {
      case 'Super Admin':
        return this.translate.instant('user.role.super');
      case 'Admin':
        return this.translate.instant('user.role.admin');
      case 'Upload':
        return this.translate.instant('header.menu.upload');
      case 'View':
        return this.translate.instant('user.role.view');
      default:
        return role;
    }
  }
}
