import {AfterContentInit, Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {ModalService, NotificationService} from 'carbon-components-angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CompaniesService} from '../../services/companies.service';
import {CompaniesStatusModalComponent} from '../companies-status-modal/companies-status-modal.component';
import {Observable, Subject} from 'rxjs';
import {AuthService} from '../../shared/auth.service';
import {CompaniesDuplicatesModalComponent} from '../companies-duplicates-modal/companies-duplicates-modal.component';
import {AppService} from '../../app.service';
import {HelperService} from '../../services/helper.service';
import {TranslateService} from '@ngx-translate/core';
declare const require;

@Component({
  selector: 'app-companies-modal',
  templateUrl: './companies-modal.component.html',
  styleUrls: ['./companies-modal.component.scss']
})
export class CompaniesModalComponent implements OnInit, AfterContentInit {
  @Output() close = new EventEmitter();
  protected data: Observable<string> = new Subject<string>();
  protected confirm: Observable<string> = new Subject<string>();
  formGroup: FormGroup;
  isLoading = false;
  title = 'Add Company';
  countries = [];
  statuses = [
    {
      status: true,
      default: 'customer',
      content: 'Customer',
    },
    {
      status: true,
      default: 'declined',
      content: 'Declined',
    },
    {
      status: false,
      default: 'pending',
      content: 'Pending',
    },
  ];
  company;
  currentCountry;
  companies = [];
  hasUploadedDocs = false;

  constructor(@Inject('id') public id,
              @Inject('country') public country,
              @Inject('options') public options,
              protected formBuilder: FormBuilder,
              private companiesService: CompaniesService,
              private notificationService: NotificationService,
              private router: Router,
              public modalService: ModalService,
              private route: ActivatedRoute,
              private authService: AuthService,
              private appService: AppService,
              public translate: TranslateService,
              private helperService: HelperService) {
  }

  ngOnInit() {
    console.log('Company ID', this.id);

    this.countries = this.appService.getCountries();

    this.formGroup = this.formBuilder.group({
      taxId: ['', [
        Validators.required,
        Validators.pattern(/.*\S+.*/),
        Validators.pattern(/^[\w.-/$&+,:;=?@#|'<>.^*()%!-–]+$/)
      ]
      ],
      name: ['', [
        Validators.required,
        Validators.pattern(/.*\S+.*/)
      ]],
      // address: [''],
      address1: ['', [
        Validators.pattern(/.*\S+.*/),
      ]],
      address2: ['', [
        Validators.pattern(/.*\S+.*/),
      ]],
      city: ['', [
        Validators.pattern(/.*\S+.*/),
      ]],
      state: ['', [
        Validators.pattern(/.*\S+.*/),
      ]],
      postcode: ['', [
        Validators.pattern(/.*\S+.*/),
      ]],
      country: ['', Validators.required],
      isCustomer: ['pending', Validators.required],
    });

    if (this.id) {
      this.title = 'Edit Company';

      this.companiesService.getCompanyById(this.id)
        .subscribe(data => {
          this.company = data['data']['companyGetById']['company'];

          this.formGroup.patchValue(this.company);

          if (this.company.address) {
            this.formGroup.patchValue({
              postcode: this.company.address.split(/\r?\n/)[0],
              state: this.company.address.split(/\r?\n/)[1],
              city: this.company.address.split(/\r?\n/)[2],
              address1: this.company.address.split(/\r?\n/)[3],
              address2: this.company.address.split(/\r?\n/)[4],
            });
          }

          this.statuses = this.statuses.map(item => {
            if (this.company.status === 'customer' && item['default'] === 'customer') {
              item['selected'] = true;

              return item;
            }

            if (this.company.status === 'pending' && item['default'] === 'pending') {
              item['selected'] = true;

              return item;
            }

            if (this.company.status === 'declined' && item['default'] === 'declined') {
              item['selected'] = true;

              return item;
            }

            return item;
          });

          this.formGroup.get('taxId').disable();
          }, (error) => {
          this.showNotification('error', error['graphQLErrors'][0]['message']);

          console.log('there was an error sending the query', error);
        });
    } else {
      this.formGroup.get('country').patchValue(this.country);

      this.formGroup.get('country').disable();

      this.statuses = this.statuses.filter(item => {
        return item['default'] !== 'declined';
      });
    }

    this.authService.authInfo$.subscribe(authInfo => {
      this.currentCountry = authInfo.currentCountry;

      if (this.hasState(this.currentCountry)) {
        this.formGroup.get('state').enable();
      } else {
        this.formGroup.get('state').disable();
      }
    });
  }

  getCompanies(id) {
    this.companiesService.getCompanies({
      country: this.currentCountry,
      taxId: this.formGroup.get('taxId').value
    }).valueChanges
      .subscribe(data => {
        this.companies = data['data']['companyGetByFilter']['companies'].filter(item => item['id'] != id);
        }, (error) => {
        this.showNotification('error', error['graphQLErrors'][0]['message']);

        console.log('there was an error sending the query', error);
      });
  }

  isErrorVisible(field, error, select?) {
    if (select) {
      return this.formGroup.controls[field].touched
        && this.formGroup.controls[field].errors
        && this.formGroup.controls[field].errors[error];
    } else if (field == 'taxId'
      && this.formGroup.get('country').value && this.helperService.oneOf(this.formGroup.get('country').value, ['BIH', 'MKD', 'SRB', 'MNE'])
      && this.formGroup.controls[field].value
    ) {
      const illegal = !this.appService.validateTaxControl(
        this.formGroup.controls[field].value,
        this.formGroup.get('country').value
      );

      this.formGroup.controls[field].setErrors(illegal ? { illegal } : null);

      return this.formGroup.controls[field].dirty
        && this.formGroup.controls[field].errors
        && this.formGroup.controls[field].errors[error];
    } else {
        return this.formGroup.controls[field].dirty
          && this.formGroup.controls[field].errors
          && this.formGroup.controls[field].errors[error];
    }
  }

  closeModal(): void {
    this.close.emit();
  }

  showNotification(type, message) {
    this.notificationService.showNotification({
      type,
      title: 'Companies',
      message,
      target: '.notification-container',
      duration: 30000
    });
  }

  addCompany() {
    // validations first
    if (this.helperService.oneOf(this.formGroup.get('country').value, ['BIH', 'MKD', 'SRB', 'MNE'])
        && !this.appService.validateTaxControl(this.formGroup.get('taxId').value, this.formGroup.get('country').value)) {
      this.isLoading = false;

      this.showNotification('error', 'Illegal JIB');

      return false;
    }

    this.isLoading = true;

    if (this.id) {
      this.companiesService.modifyCompany(this.id, {
        name: this.formGroup.get('name').value,
        address: `${this.formGroup.get('postcode').value}
${this.formGroup.get('state').value}
${this.formGroup.get('city').value}
${this.formGroup.get('address1').value}
${this.formGroup.get('address2').value}`,
        country: this.formGroup.get('country').value,
        isCustomer: this.formGroup.get('isCustomer').value,
      }, this.options)
        .subscribe(data => {
          this.isLoading = false;

          this.closeModal();

          if (this.formGroup.get('isCustomer').touched && this.formGroup.get('isCustomer').value === 'customer') {
            this.checkStatus(this.id);
          }

          if (this.company.status === 'customer' && this.formGroup.get('isCustomer').value === 'pending') {
            this.convertCompanyBackFromCustomer(this.id);
          }

          if (this.company.status === 'customer' && this.formGroup.get('isCustomer').value === 'declined') {
            this.convertCompanyBackFromCustomer(this.id, true);
          }

          if (this.company.status === 'declined' && this.formGroup.get('isCustomer').value === 'pending') {
            this.companySetStatusPending(this.id);
          }

          if (this.company.status === 'pending' && this.formGroup.get('isCustomer').value === 'declined') {
            this.companySetStatusDeclined(this.id);
          }

        }, (error) => {
          this.isLoading = false;

          this.showNotification('error', `Company with Tax Id ${this.formGroup.get('taxId').value} already exists as a customer. Change the status or delete the other company before marking this one as a Customer.`);

          console.log('there was an error sending the query', error);
        });
    } else {
      this.companiesService.addCompany({
        taxId: this.formGroup.get('taxId').value,
        name: this.formGroup.get('name').value,
        address: `${this.formGroup.get('postcode').value}
${this.formGroup.get('state').value}
${this.formGroup.get('city').value}
${this.formGroup.get('address1').value}
${this.formGroup.get('address2').value}`,
        country: this.formGroup.get('country').value,
        isCustomer: this.formGroup.get('isCustomer').value,
      }, this.options)
        .subscribe(data => {
          this.isLoading = false;

          this.closeModal();

          if (this.formGroup.get('isCustomer').value === 'customer') {
            this.checkStatus(data['data']['addCompany']['company']['id']);
          } else {
            this.showNotification('success', 'Company was added successfully');
          }
        }, (error) => {
          this.isLoading = false;

          this.showNotification('error', `Company with Tax Id ${this.formGroup.get('taxId').value} already exists as a customer. Change the status or delete the other company before marking this one as a Customer.`);

          console.log('there was an error sending the query', error);
        });
    }
  }

  companySetStatusDeclined(id) {
    this.companiesService.companySetStatusDeclined(id, this.formGroup.get('country').value, this.options)
      .subscribe(data => {
        console.log(data);

        this.showNotification('success', 'Company was updated successfully');
      }, (error) => {
        this.showNotification('error', error['graphQLErrors'][0]['message']);

        console.log('there was an error sending the query', error);
      });
  }

  companySetStatusPending(id) {
    this.companiesService.companySetStatusPending(id, this.formGroup.get('country').value, this.options)
      .subscribe(data => {
        console.log(data);

        this.showNotification('success', 'Company was updated successfully');
      }, (error) => {
        this.showNotification('error', error['graphQLErrors'][0]['message']);

        console.log('there was an error sending the query', error);
      });
  }

  checkStatus(id) {
    // this.getCompanies(id);

    this.companiesService.getCompaniesOnce({
      country: this.currentCountry,
      taxId: this.formGroup.get('taxId').value
    }).subscribe(data => {

        this.companies = data['data']['companyGetByFilter']['companies']
          .filter(item => item['id'] != id)
          .filter(item => item['status'] != 'customer');

        console.log(this.companies);

        // console.log(this.model.data);

        if (this.companies.length) {
          this.modalService.create({
            component: CompaniesDuplicatesModalComponent,
            inputs: {
              companies: this.companies,
              confirm: this.confirm,
              id
            },
          });
        } else {
          this.checkHasUploadedDocs(id);
        }
      }, (error) => {
      this.isLoading = false;

      this.showNotification('error', error['graphQLErrors'][0]['message']);

      console.log('there was an error sending the query', error);
    });
  }

  convertCompanyToCustomer(id, country) {
    this.companiesService.convertCompanyToCustomer(id, country, this.options)
      .subscribe(data => {
        this.companiesService.emailSendApprovalMessage(id)
          .subscribe(data => {
            this.isLoading = false;

            this.showNotification('success', 'Company was updated successfully');
          }, (error) => {
            this.showNotification('error', error['graphQLErrors'][0]['message']);

            console.log('there was an error sending the query', error);
          });
      }, error => {
        this.showNotification('error', `Company with Tax Id ${this.formGroup.get('taxId').value} already exists as a customer. Change the status or delete the other company before marking this one as a Customer.`);

        console.log('there was an error sending the query', error);
      });
  }

  convertCompanyBackFromCustomer(id, declined?) {
    this.companiesService.convertCompanyBackFromCustomer(id, this.formGroup.get('country').value, this.options)
      .subscribe(data => {
          if (declined) {
            this.companySetStatusDeclined(id);
          } else {
            this.showNotification('success', 'Company was updated successfully');
          }
        }, (error) => {
          this.showNotification('error', error['graphQLErrors'][0]['message']);

          console.log('there was an error sending the query', error);
      });
  }

  ngAfterContentInit() {
    this.data.subscribe(id => {
      this.convertCompanyToCustomer(id, this.formGroup.get('country').value);
    }, (error) => {
      this.showNotification('error', error['graphQLErrors'][0]['message']);

      console.log('there was an error sending the query', error);
    });

    this.confirm.subscribe(id => {
      this.checkHasUploadedDocs(id);
    }, (error) => {
      this.showNotification('error', error['graphQLErrors'][0]['message']);

      console.log('there was an error sending the query', error);
    });
  }

  checkHasUploadedDocs(id) {
    this.appService.listCompanyDocuments(id)
      .subscribe(data => {
        this.hasUploadedDocs = data['data']['companyListDocuments'].length;

        if (!this.hasUploadedDocs) {
          this.showUploadedDocsModal(id);
        } else {
          this.convertCompanyToCustomer(id, this.formGroup.get('country').value);
        }
      }, (error) => {
        this.showNotification('error', error['graphQLErrors'][0]['message']);

        console.log('there was an error sending the query', error);
      });

    return !!JSON.parse(localStorage.getItem(`files-${id}`));
  }

  showUploadedDocsModal(id) {
    this.modalService.create({
      component: CompaniesStatusModalComponent,
      inputs: {
        company: {
          taxId: this.formGroup.get('taxId').value,
          name: this.formGroup.get('name').value,
          id
        },
        data: this.data
      },
    });
  }

  hasState(country) {
    return this.appService.hasState(country);
  }
}
