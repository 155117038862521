import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FileUploaderModule, NotificationService, PlaceholderService} from 'carbon-components-angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GraphQLModule} from './graphql.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {AppService} from './app.service';
import {SigninComponent} from './components/signin/signin.component';
import {AuthGuard} from './shared/auth.guard';
import {MainComponent} from './components/main/main.component';
import {CompaniesModalComponent} from './components/companies-modal/companies-modal.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CompaniesService} from './services/companies.service';
import {SignupComponent} from './components/signup/signup.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {ConfirmSignupComponent} from './components/confirm-signup/confirm-signup.component';
import {SignupDuplicateComponent} from './components/signup-duplicate/signup-duplicate.component';
import {DetailsService} from './services/details.service';
import {AuthService} from './shared/auth.service';
import {CompanyUsersModalComponent} from './components/company-users-modal/company-users-modal.component';
import {InputFocusDirective} from './directives/autofocus.directive';
import {RoundsService} from './services/rounds.service';
import {DebCredsService} from './services/debcreds.service';
import {FileUploadModalComponent} from './components/file-upload-modal/file-upload-modal.component';
import {PermissionsService} from './services/permissions.service';
import {RoleGuard} from './shared/roles.guard';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {CompaniesStatusModalComponent} from './components/companies-status-modal/companies-status-modal.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CompaniesDuplicatesModalComponent} from './components/companies-duplicates-modal/companies-duplicates-modal.component';
import {DeleteModalComponent} from './components/delete-modal/delete-modal.component';
import {DecimalPipe} from '@angular/common';
import {UnassignModalComponent} from './components/unassign-modal/unassign-modal.component';
import {DeleteUserModalComponent} from './components/delete-user-modal/delete-user-modal.component';
import {AuthHeaderComponent} from './components/auth-header/auth-header.component';
import {BaseModule} from './modules/base/base.module';
import {AmplifyAngularModule, AmplifyService} from 'aws-amplify-angular';
import {DeleteCompanyModalComponent} from './components/delete-company-modal/delete-company-modal.component';
import {CreateCompanyComponent} from './components/create-company/create-company.component';
import {CompanyVendorsModalComponent} from './components/company-vendors-modal/company-vendors-modal.component';
import {ReferralsService} from './services/referrals.service';
import {GoogleAnalyticsService, NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    MainComponent,
    CompaniesModalComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ConfirmSignupComponent,
    SignupDuplicateComponent,
    CompanyUsersModalComponent,
    InputFocusDirective,
    FileUploadModalComponent,
    NotFoundComponent,
    CompaniesStatusModalComponent,
    CompaniesDuplicatesModalComponent,
    DeleteModalComponent,
    UnassignModalComponent,
    DeleteUserModalComponent,
    DeleteCompanyModalComponent,
    AuthHeaderComponent,
    CreateCompanyComponent,
    CompanyVendorsModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FileUploaderModule,
    FormsModule,
    GraphQLModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BaseModule,
    AmplifyAngularModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxGoogleAnalyticsModule.forRoot('G-X14SEDSTZ9'),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [
    AppService,
    NotificationService,
    AuthGuard,
    CompaniesService,
    DetailsService,
    AuthService,
    RoundsService,
    DebCredsService,
    PlaceholderService,
    PermissionsService,
    RoleGuard,
    DecimalPipe,
    AmplifyService,
    ReferralsService,
    GoogleAnalyticsService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    CompanyUsersModalComponent,
    FileUploadModalComponent,
    CompaniesModalComponent,
    CompaniesStatusModalComponent,
    CompaniesDuplicatesModalComponent,
    DeleteModalComponent,
    UnassignModalComponent,
    DeleteUserModalComponent,
    DeleteCompanyModalComponent,
    CompanyVendorsModalComponent,
  ]
})
export class AppModule { }
