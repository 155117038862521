import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NotificationService} from 'carbon-components-angular';
import {RoundsService} from '../../services/rounds.service';
import {AuthService} from '../../shared/auth.service';
import {AppService} from '../../app.service';
import {CompaniesService} from '../../services/companies.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  lastRoundId = null;
  isLoading = false;
  // @ViewChild('warning', {static: true})
  // protected warning: TemplateRef<any>;
  countriesAll;
  currentCountryInfo;
  currentCountryInfoMain = null;
  currentCountryInfoSub = null;
  userId;
  companyId;
  company;

  constructor(private notificationService: NotificationService,
              private roundsService: RoundsService,
              private authService: AuthService,
              private appService: AppService,
              private companiesService: CompaniesService,
              public translate: TranslateService) { }

  ngOnInit() {
    this.authService.authInfo$.subscribe(authInfo => {
      this.appService.countriesGetAll().valueChanges
        .subscribe(data => {
          // console.log(data);

          this.countriesAll = data['data']['countriesGetAll'];

          // console.log(this.countriesAll);

          if (authInfo.email) {
            this.appService.userGetByEmail(authInfo.email)
              .subscribe(data => {
                // console.log(data);

                this.userId = data['data']['userGetByEmail']['user']['id'];

                this.appService.companyGetByUser(this.userId)
                  .subscribe(data => {
                    this.isLoading = false;
                    // console.log(data);

                    if (authInfo.currentCompany) {
                      this.companyId = authInfo.currentCompany;
                    } else {
                      this.companyId = data['data']['companyGetByUser'] && data['data']['companyGetByUser']['user']['companies'][0]['id'];
                    }

                    this.companiesService.getCompanyById(this.companyId)
                      .subscribe(data => {
                        // console.log(data);

                        this.company = data['data']['companyGetById'] && data['data']['companyGetById']['company'];

                        this.isLoading = false;

                        this.roundsService.getLastRound(this.company['country']).valueChanges
                          .subscribe(data => {
                            // console.log(data);

                            this.currentCountryInfo = this.countriesAll.find(item => item['alpha3'] === this.company['country']);

                            this.currentCountryInfoMain = this.currentCountryInfo && this.currentCountryInfo['mainMessage'];

                            this.currentCountryInfoSub = this.currentCountryInfo && this.currentCountryInfo['subMessage'];

                            // console.log(this.currentCountryInfoMain);

                            // console.log(this.currentCountryInfoSub);

                            this.lastRoundId = data['data']['roundGetLast'] && data['data']['roundGetLast']['round']['id'];

                            this.isLoading = true;
                          }, (error) => {
                            error.graphQLErrors.map((e) => {
                              if (e.errorInfo && e.errorInfo.statusCode === 1204) {
                                this.showNotification('error', `${this.translate.instant('upload.file.noround')}`);
                              } else {
                                this.showNotification('error', error['graphQLErrors'][0]['message']);
                              }
                            });

                            console.log('there was an error sending the query', error);
                          });
                      }, (error) => {
                        error.graphQLErrors.map((e) => {
                          if (e.errorInfo && e.errorInfo.statusCode === 1002) {
                            this.showNotification('error', `${this.translate.instant('errors.company.args')}`);
                          } else if (e.errorInfo && e.errorInfo.statusCode === 1004) {
                            this.showNotification('error', `${this.translate.instant('errors.company.notfound')}`);
                          } else {
                            this.showNotification('error', error['graphQLErrors'][0]['message']);
                          }
                        });

                        console.log('there was an error sending the query', error);
                      });
                  }, (error) => {
                    console.log('there was an error sending the query', error);
                  });
              }, (error) => {
                error.graphQLErrors.map((e) => {
                  if (e.errorInfo && e.errorInfo.statusCode === 1104) {
                    this.showNotification('error', `${this.translate.instant('errors.users.nofound')}`);
                  } else {
                    this.showNotification('error', error['graphQLErrors'][0]['message']);
                  }
                });

                console.log('there was an error sending the query', error);
              });
          }
        }, (error) => {
          console.log('there was an error sending the query', error);
        });
    }, (error) => {
      console.log('there was an error sending the query', error);
    });
  }

  showNotification(type, message) {
    this.notificationService.showNotification({
      type,
      title: this.translate.instant('company.details.tab.general'),
      message,
      target: '.notification-container',
      duration: 30000
    });
  }

}
