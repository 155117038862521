import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable()
export class ReferralsService {
  referralGetQuery = gql`query ReferralGet(
    $referral: ReferralFilterInput
    $options: SelectOptions
    $search: String
  ) {
    referralGet(
      referral: $referral
      options: $options
      search: $search
    ) {
      referrals {
        id
        referringCompanyId
        referringUserId
        referredCompanyId
        referredCompanyName
        referredCompanyTaxId
        referredCompanyCountry
        referredCompanyStatus
        referralDate
        referralCode
        contactEmail
        contactFirstName
        contactLastName
        contactPhone
      }
      count
      page
      pageSize
    }
  }`;

  referralAddMutation = gql`mutation ReferralAdd($referral: ReferralInput) {
    referralAdd(referral: $referral) {
      referral {
        id
        referringCompanyId
        referringUserId
        referredCompanyId
        referredCompanyName
        referredCompanyTaxId
        referredCompanyCountry
        referredCompanyStatus
        referralDate
        referralCode
        contactEmail
        contactFirstName
        contactLastName
        contactPhone
	    }
    }
  }`;

  referralModifyMutation = gql`mutation ReferralModify($referral: ReferralModifyInput) {
    referralModify(referral: $referral) {
      referral {
        id
        referringCompanyId
        referringUserId
        referredCompanyId
        referredCompanyName
        referredCompanyTaxId
        referredCompanyCountry
        referredCompanyStatus
        referredCompanyDeleted
        referralDate
        referralCode
        contactEmail
        contactFirstName
        contactLastName
        contactPhone
      }
    }
  }`;

  referralRemoveMutation = gql`mutation ReferralRemove($referral: ReferralIDInput) {
    referralRemove(referral: $referral) {
      referral {
        id
        referringCompanyId
        referringUserId
        referredCompanyId
        referredCompanyName
        referredCompanyTaxId
        referredCompanyCountry
        referredCompanyStatus
        referredCompanyDeleted
        referralDate
        referralCode
        contactEmail
        contactFirstName
        contactLastName
        contactPhone
      }
    }
  }`;

  generateVendorsInvitationMutation = gql`mutation GenerateVendorsInvitation($invitationInput: VendorsInvitationInput) {
    generateVendorsInvitation(invitationInput: $invitationInput) {
      subject
      body
    }
  }`;

  generatePaymentApproveNotificationQuery = gql`query GeneratePaymentApproveNotification($notificationInput: PaymentApproveNotificationInput!) {
    generatePaymentApproveNotification(notificationInput: $notificationInput) {
      subject
      body
      toEmail
    }
  }`;

  emailSendToManagerOnNewContactQuery = gql`query EmailSendToManagerOnNewContact(
    $referrals: [ReferralIDInput]
    $company: CompanyIdentInput
  ) {
    emailSendToManagerOnNewContact(referrals: $referrals, company: $company) {
      emailSentNum
      sendErrors {
        to
        __error_message
        __msg_id
      }
    }
  }`;

  constructor(private apollo: Apollo) {}

  referralGet(referral, options?, search?) {
    // console.log(referral);

    return this.apollo.query({
      query: this.referralGetQuery,
      fetchPolicy: 'network-only',
      variables: {
        referral,
        options,
        search,
      }
    });
  }

  referralAdd(referral, company) {
    // console.log(referral);

    return this.apollo.mutate({
      mutation: this.referralAddMutation,
      variables: {
        referral
      },
    });
  }

  referralModify(referral, company) {
    // console.log(referral);

    return this.apollo.mutate({
      mutation: this.referralModifyMutation,
      variables: {
        referral
      },
    });
  }

  referralRemove(referral, company) {
    // console.log(referral);

    return this.apollo.mutate({
      mutation: this.referralRemoveMutation,
      variables: {
        referral
      },
    });
  }

  generateVendorsInvitation(data) {
    // console.log(data);

    return this.apollo.mutate({
      mutation: this.generateVendorsInvitationMutation,
      variables: {
        invitationInput: data
      },
    });
  }

  generatePaymentApproveNotification(data) {
    return this.apollo.query({
      query: this.generatePaymentApproveNotificationQuery,
      fetchPolicy: 'network-only',
      variables: {
        notificationInput: data
      }
    });
  }

  sendEmailToManager(referrals, companyId) {
    return this.apollo.query({
      fetchPolicy: 'network-only',
      query: this.emailSendToManagerOnNewContactQuery,
      variables: {
        company: {
          id: companyId,
        },
        referrals
      }
    });
  }
}
