import {Component, OnInit} from '@angular/core';
import {AuthState} from 'aws-amplify-angular/dist/src/providers';
import {AmplifyService} from 'aws-amplify-angular';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from 'carbon-components-angular';
import {TranslateService} from '@ngx-translate/core';
import {Auth} from 'aws-amplify';

@Component({
  selector: 'app-confirm-signup',
  templateUrl: './confirm-signup.component.html',
  styleUrls: ['./confirm-signup.component.scss']
})
export class ConfirmSignupComponent implements OnInit {
  authState: AuthState;
  username;
  code;
  isLoading = false;
  password;
  invited = false;

  constructor(private amplifyService: AmplifyService,
              private route: ActivatedRoute,
              private router: Router,
              private notificationService: NotificationService,
              public translate: TranslateService) {
  }

  ngOnInit() {
    if (this.route.snapshot.queryParams['invited']) {
      this.invited = this.route.snapshot.queryParams['invited'];
    }

    if (JSON.parse(localStorage.getItem('auth'))) {
      const data = JSON.parse(localStorage.getItem('auth'));

      this.password = data['password'];
    }

    this.route.queryParams.subscribe(params => {
      this.username = params['username'] || JSON.parse(localStorage.getItem('username'));

      if (this.username) {
        this.authState = {
          user: {
            username: this.username
          },
          state: 'confirmSignUp'
        };

        this.amplifyService.setAuthState(this.authState);
      }
    });
  }

  showNotification(type, message) {
    this.notificationService.showNotification({
      type,
      title: 'Auth',
      message,
      target: '.notification-container',
      duration: 30000
    });
  }

  sendCodeAgain() {
    this.isLoading = true;

    Auth.resendSignUp(String(this.username).toLowerCase())
      .then((data: any) => {
        this.isLoading = false;

        this.showNotification('success', this.translate.instant('verification.code.sent'));
      })
      .catch(err => {
        this.isLoading = false;

        this.showNotification('error', err['message']);
      });
  }

  confirm() {
    this.isLoading = true;

    const code = this.code.replace(/\s/g, '');

    Auth.confirmSignUp(String(this.username).toLowerCase(), code)
      .then((data) => {
        Auth.signIn(String(this.username).toLowerCase(), this.password)
          .then(user => {
            this.amplifyService.setAuthState({ state: 'signedIn', user });

            this.isLoading = true;

            if (this.invited) {
              this.router.navigate(['/home']);
            } else {
              this.router.navigate(['/create-company']);
            }
          })
          .catch(err => {
            this.isLoading = false;

            this.showNotification('error', err['message']);
          });
      })
      .catch(err => {
        if (err['code'] == 'CodeMismatchException') {
          this.showNotification('error', this.translate.instant('auth.incorrect.code'));
        } else if (err['code'] == 'NotAuthorizedException') {
          this.showNotification('error', this.translate.instant('auth.incorrect.confirmed'));
        } else {
          this.showNotification('error', err['message']);
        }
      });
  }
}
