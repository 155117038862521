import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {forkJoin} from 'rxjs';
import {AuthService} from '../../shared/auth.service';
import {AppService} from '../../app.service';
import {CompaniesService} from '../../services/companies.service';

@Component({
  selector: 'app-unassign-modal',
  templateUrl: './unassign-modal.component.html',
  styleUrls: ['./unassign-modal.component.scss']
})
export class UnassignModalComponent implements OnInit {
  @Output() close = new EventEmitter();
  companyId;
  company;
  user;
  isLoading = false;

  constructor(@Inject('id') public id,
              @Inject('data') public data,
              @Inject('currentCompany') public currentCompany,
              private authService: AuthService,
              private appService: AppService,
              private companiesService: CompaniesService) { }

  ngOnInit() {
    this.isLoading = true;

    this.authService.authInfo$.subscribe(authInfo => {
      if (authInfo.email && authInfo.currentCountry) {
        this.appService.userGetByEmail(authInfo.email)
          .subscribe(data => {
            // console.log(data);

            this.appService.companyGetByUser(this.id)
              .subscribe(data => {
                // console.log(data);

                if (authInfo.currentCompany) {
                  this.companyId = authInfo.currentCompany;
                } else {
                  this.companyId = data['data']['companyGetByUser'] && data['data']['companyGetByUser']['user']['companies'][0]['id'];
                }

                if (this.currentCompany) {
                  this.companyId = this.currentCompany;
                }

                if (this.companyId) {
                  this.appService.companyGetById(this.companyId)
                    .subscribe(data => {
                      // console.log(data);

                      this.company = data['data']['companyGetById']['company'];

                      this.getUser(this.id);
                    });
                }
              });
          });
      }
    });
  }

  getUser(id) {
    this.companiesService.userGetById(this.id).valueChanges
      .subscribe(data => {
        // console.log(data);

        this.user = data['data']['userGetById']['user'];

        this.isLoading = false;
      });
  }

  closeModal(): void {
    this.close.emit();
  }

  proceed() {
    this.close.emit();

    this.data.next(this.id);
  }
}
