import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AmplifyService} from 'aws-amplify-angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from 'carbon-components-angular';
import {DetailsService} from '../../services/details.service';
import {AppService} from '../../app.service';
import {CompaniesService} from '../../services/companies.service';
import {HelperService} from '../../services/helper.service';
import {PermissionsService} from '../../services/permissions.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Auth} from 'aws-amplify';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: [
    '../auth/auth.scss',
    './signup.component.scss'
  ]
})
export class SignupComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  formGroup: FormGroup;
  currentStep = 0;
  forgotPassword;
  isLoading = false;
  userId;
  companyId;
  countries = [];
  assigned;
  email;
  skip = false;
  company;
  userCountry = '';
  @ViewChild('customNotificationContent', {static: true}) public customNotificationContent: TemplateRef<any>;
  username;
  countriesActive = [];
  userData;
  lang;
  currentActiveCountry = null;
  samplePassword;
  selectedCountry = null;
  capsOn = false;

  constructor(private amplifyService: AmplifyService,
              protected formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private notificationService: NotificationService,
              private router: Router,
              private detailsService: DetailsService,
              private appService: AppService,
              private companiesService: CompaniesService,
              private permissionsService: PermissionsService,
              private http: HttpClient,
              public translate: TranslateService,
              private helperService: HelperService) {
  }

  ngOnInit() {
    this.countries = this.appService.getCountries();

    this.countries = this.countries.map(item => {
      return {
        content: item[this.translate.currentLang],
        code: item.code,
        en: item.en,
        mk: item.mk,
        hr: item.hr,
        sr: item.sr,
        cn: item.cn,
        el: item.el,
        alpha2: item.alpha2
      };
    }).sort((a, b) => {
      return a.content.localeCompare(b.content);
    });

    this.subscription.add(this.translate.onLangChange
      .subscribe(data => {
        this.lang = data['lang'];

        this.countries = this.countries.map(item => {
          return {
            content: item[this.lang],
            code: item.code,
            en: item.en,
            mk: item.mk,
            hr: item.hr,
            sr: item.sr,
            cn: item.cn,
            el: item.el,
            alpha2: item.alpha2
          };
        }).sort((a, b) => {
          return a.content.localeCompare(b.content);
        });
      }));

    this.appService.countriesGetActive()
      .subscribe(data => {
        this.countriesActive = data['data']['countriesGetActive'];

        this.getCurrentActiveCountry(this.formGroup.get('country').value);
        }, (error) => {
        this.showNotification('error', error['graphQLErrors'][0]['message']);

        console.log('there was an error sending the query', error);
      });

    this.formGroup = this.formBuilder.group({
      email: ['', [
          Validators.required,
          Validators.pattern(/.*\S+.*/),
          Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')
        ]
      ],
      email2: [''],
      companyName: ['', [
        Validators.required,
        Validators.pattern(/.*\S+.*/),
      ]],
      country: ['', Validators.required],
      firstName: ['', [
        Validators.required,
        Validators.pattern(/.*\S+.*/),
      ]],
      lastName: ['', [
        Validators.required,
        Validators.pattern(/.*\S+.*/),
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?:(?=.*\d)(?=.*[a-z]).*)$/),
      ]],
      confirmPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?:(?=.*\d)(?=.*[a-z]).*)$/),
      ]],
      role: ['', Validators.pattern(/.*\S+.*/)],
      phone: [''],
    });

    if (JSON.parse(localStorage.getItem('auth'))) {
      const data = JSON.parse(localStorage.getItem('auth'));

      this.formGroup.patchValue(data);
    }

    this.formGroup.valueChanges
      .subscribe(data => {
        const formData = data;

        formData.email = String(data.email).toLowerCase();

        formData.email2 = String(data.email2).toLowerCase();

        localStorage.setItem('auth', JSON.stringify(data));
      });

    if (this.route.snapshot.queryParams['data']) {
      this.userData = JSON.parse(atob(this.route.snapshot.queryParams.data));

      console.log(this.userData);
      this.userData.email2 = this.userData.email;
      localStorage.setItem('lang', JSON.stringify(this.userData.language));
      this.translate.use(this.userData.language);
      this.formGroup.patchValue(this.userData);

      this.currentStep = 1;
    }

    this.formGroup.get('email').valueChanges
      .subscribe(data => {
        this.formGroup.get('email2').patchValue(data);
      });

    this.getSamplePassword();

    this.formGroup.get('companyName').valueChanges
      .subscribe(data => {
        this.getSamplePassword();
      });

    this.formGroup.get('country').valueChanges
      .subscribe(data => {
        this.getCurrentActiveCountry(data);
      });

    this.route.queryParams.subscribe(params => {
      if (!this.username) {
        this.http.get(environment.ipiApi)
          .subscribe(data => {
            console.log(data);

            this.userCountry = data['country_code'];

            this.countries = this.countries.map(item => {
              if (item.alpha2 === this.userCountry) {
                this.formGroup.get('country').patchValue(item.code);

                this.getCurrentActiveCountry(item.code);
              }

              if (this.userData && this.userData.country) {
                this.formGroup.get('country').patchValue(this.userData.country);

                this.getCurrentActiveCountry(this.userData.country);
              }

              return{
                content: item[this.translate.currentLang],
                code: item.code,
                en: item.en,
                mk: item.mk,
                hr: item.hr,
                sr: item.sr,
                cn: item.cn,
                el: item.el,
                alpha2: item.alpha2,
                selected: this.userData && this.userData.country
                  ? item.alpha2 === this.userData.country
                  : item.alpha2 === this.userCountry
              };
            });

          }, (error) => {
            this.showNotification('error', error['graphQLErrors'][0]['message']);

            console.log('there was an error sending the query', error);
          });
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  createRequest(formValue, userId?, invited?) {
    this.isLoading = true;

    Auth.signUp({
      username: String(formValue.email).toLowerCase(),
      password: formValue.password,
      attributes: {
        'custom:user_lang': this.translate.currentLang,
        ...(userId) && { 'custom:user_id': userId}
      }
    })
      .then((user: any) => {
        this.isLoading = false;

        localStorage.setItem('username', JSON.stringify(user.user.username));

        this.router.navigate(['/confirm-signup'], {
          queryParams: {
            username: String(this.formGroup.get('email').value).toLowerCase(),
            ...(this.userData) && { invited: true }
          }
        });
      })
      .catch(err => {
        this.router.navigate(['/signup-duplicate'], { queryParams: { username: String(this.formGroup.get('email').value).toLowerCase() } });

        this.isLoading = false;

        this.showNotification('error', err['message']);
      });
  }

  showNotification(type, message) {
    this.notificationService.showNotification({
      type,
      title: 'Auth',
      message,
      target: '.notification-container',
      duration: 30000
    });
  }

  isErrorVisible(field, error, select?) {
    if (select) {
      return this.formGroup.controls[field].touched
        && this.formGroup.controls[field].errors
        && this.formGroup.controls[field].errors[error];
    } else if (field == 'taxId'
      && this.formGroup.get('country2').value && this.helperService.oneOf(this.formGroup.get('country2').value, ['BIH', 'MKD', 'SRB', 'MNE'])
      && this.formGroup.controls[field].value
    ) {
      const illegal = !this.appService.validateTaxControl(
        this.formGroup.controls[field].value,
        this.formGroup.get('country2').value
      );

      this.formGroup.controls[field].setErrors(illegal ? { illegal } : null);

      return this.formGroup.controls[field].dirty
        && this.formGroup.controls[field].errors
        && this.formGroup.controls[field].errors[error];
    } else {
      return this.formGroup.controls[field].dirty
        && this.formGroup.controls[field].errors
        && this.formGroup.controls[field].errors[error];
    }
  }

  isPasswordMatch() {
    const val = this.formGroup.value;

    const pristine = this.formGroup.get('confirmPassword').pristine;

    const invalid = this.formGroup.get('confirmPassword').invalid;

    return pristine ||  !invalid && val && val.password && val.password === val.confirmPassword;
  }

  addUser() {
    this.isLoading = true;

    if (this.userData) {
      this.appService.registrationProceed({
        email: String(this.formGroup.get('email').value).toLowerCase(),
        firstName: this.formGroup.get('firstName').value,
        lastName: this.formGroup.get('lastName').value,
        role: this.formGroup.get('role').value,
        phone: this.formGroup.get('phone').value,
        language: this.translate.currentLang,
      })
        .subscribe(data => {
          this.createRequest(this.formGroup.value);
          //
          this.isLoading = false;
        }, (error) => {
          this.isLoading = false;

          this.userIsRegistered();

          console.log('there was an error sending the query', error);
        });

    } else {
      this.detailsService.addUser({
        email: String(this.formGroup.get('email').value).toLowerCase(),
        firstName: this.formGroup.get('firstName').value,
        lastName: this.formGroup.get('lastName').value,
        role: this.formGroup.get('role').value,
        phone: this.formGroup.get('phone').value,
        language: this.translate.currentLang,
      })
        .subscribe(data => {
          this.userId = data['data']['addUser']['user']['id'];

          this.createRequest(this.formGroup.value, this.userId);

          this.isLoading = false;
        }, (error) => {
          this.isLoading = false;

          this.userIsRegistered();

          error.graphQLErrors.map((e) => {
            if (e.errorInfo && e.errorInfo.statusCode === 1122) {
              this.showNotification('error', `${this.translate.instant('errors.used.add')}`);
            } else if (e.errorInfo && e.errorInfo.statusCode === 1101) {
              this.showNotification('error', `${this.translate.instant('errors.user.exist')}`);
            } else {
              this.showNotification('error', error['graphQLErrors'][0]['message']);
            }
          });

          console.log('there was an error sending the query', error);
        });
    }
  }

  userIsRegistered(step?) {
    this.isLoading = true;

    this.appService.userIsRegistered(String(this.formGroup.get('email').value).toLowerCase())
      .subscribe(data => {
        this.isLoading = false;

        if (data['data']['userIsRegistered'] === 1) {
          this.showNotification('error', this.translate.instant('signup.invited'));
        } else if (data['data']['userIsRegistered'] === 2 || data['data']['userIsRegistered'] === 3) {
          this.router.navigate(['/signup-duplicate'], { queryParams: { username: String(this.formGroup.get('email').value).toLowerCase() } });
        } else if (data['data']['userIsRegistered'] === 7) {
          this.router.navigate(['/confirm-signup'], { queryParams: { username: String(this.formGroup.get('email').value).toLowerCase() } });
        } else {
          if (step) {
            this.currentStep = 1;
          }
        }
      }, (error) => {
        this.isLoading = false;

        console.log('there was an error sending the query', error);
      });
  }

  checkUser() {
    this.isLoading = true;

    if (this.formGroup.get('email').untouched) {
      this.isLoading = false;

      return;
    }

    this.userIsRegistered();
  }

  getCurrentActiveCountry(country) {
    this.currentActiveCountry = this.countriesActive.find(item => item.alpha3 === country);
  }

  getSelectedCountry(code) {
    const country = this.countries.find(item => item.code === code);

    if (country) {
      this.selectedCountry = country.alpha2.toLowerCase();
    } else {
      this.selectedCountry = null;
    }
  }

  getDocumentUrl(companyCountry, document) {
    if (this.currentActiveCountry) {
      return `https://rollsoft-public-files.s3.amazonaws.com/Countries/${companyCountry}/${document}`;
    }

    if (!this.currentActiveCountry && document === 'TermsAndConditions.pdf') {
      return `https://docs.google.com/document/d/1WYWbNbSK4s6fwPbqKG1XI4k3CczbqFbn9O6-qrOeCbI/edit`;
    }

    if (!this.currentActiveCountry && document === 'CustomerAgreement.pdf') {
      return `https://rollsoft-public-files.s3.amazonaws.com/Countries/${companyCountry}/${document}`;
    }
  }

  getSamplePassword() {
    const companyNameChunk = this.formGroup.get('companyName').value.slice(0, 6).split(' ').join('');
    const randomDigits = Math.floor(100000 + Math.random() * 700000);
    const tail = String(randomDigits).slice(0, 7 - companyNameChunk.length);

    this.samplePassword =  `${companyNameChunk}x${tail}`;
  }

  isCapsOn(e) {
    if (e.getModifierState && e.getModifierState('CapsLock')) {
      this.capsOn = true;
    } else {
      this.capsOn = false;
    }
  }
}
